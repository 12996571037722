<template>
    <form @submit="send">
        <span class="note">Bitte füllen Sie alle mit * markierten Felder aus. (Pflichtfelder)</span>
        <span>Name*</span>
        <input type="text" v-model="Name" pattern="^[a-zA-Z äöüÄÖÜß]+$" :disabled="disabled" required>
        <span>Tel*</span>
        <input type="tel" v-model="Telefon" pattern="^[0-9+ ]+$" :disabled="disabled" required>
        <span>E-Mail*</span>
        <input type="email" v-model="Email" pattern="^.+@.+\..+$" :disabled="disabled" required>
        <span>Anliegen <small>(max. 300 Zeichen)</small></span>
        <textarea name="Anliegen" maxlength="300" :disabled="disabled"></textarea>
        <button type="submit" :disabled="disabled">senden</button>
        <button type="reset" :disabled="disabled">zurücksetzen</button>

        <div class="overlay cookie-message" v-if="!cookiesAllowed">
            <slot name="cookie-text"/>
            <div>
                <button @click="acceptCookies">Akzeptieren</button>
                <router-link to="/datenschutz" style="margin-left: 1em;">Datenschutz</router-link>
            </div>
        </div>
        <div class="overlay loading" v-if="loading">
            <fa icon="circle-notch"/>
        </div>
    </form>
</template>

<script>
import Cookies from 'js-cookie'

export default {
    data() {return{
        Name: '',
        Telefon: '',
        Email: '',
        cookiesAllowed: false,
        captcha: null,
        loading: false,
    }},
    computed: {
        disabled() {
            return this.loading || !this.cookiesAllowed
        }
    },
    created() {
        if (Cookies.get('cookies-allowed'))
            this.acceptCookies()
    },
    mounted() {
        if (this.captcha)
            this.captcha.showBadge()
    },
    beforeDestroy() {
        if (this.captcha)
            this.captcha.hideBadge()
    },
    methods: {
        async send(event) {
            event.preventDefault()

            if (!this.cookiesAllowed)
                return
            
            if (!this.captcha)
                return

            this.loading = true

            const data = {
                token: await this.captcha.execute('submit')
                    .catch(e => {
                        this.loading = false
                        throw e
                    }),
                Name: this.Name,
                Telefon: this.Telefon,
                Email: this.Email,
                dataFields: 'Mandant;Name;Telefon;Email;Anliegen',
                required: 'Name;Telefon;Email',
                layoutName: 'Kontakte',
                Mandant: 'avalon-orga',
            }

            fetch('https://avalon-automation.de/global/avalon-formular-rc.php', {
                method: 'POST',
                body: new URLSearchParams(data),
                mode: 'cors',
                credentials: 'omit',
            }).then(e => this.$emit('submit', e))
                .catch(e => this.$emit('error', e))
                .finally(() => this.loading = false)
        },

        async acceptCookies() {
            const load = await import(/* webpackChunkName: "recaptcha-v3" */ 'recaptcha-v3')
                .then(module => module.load)
            this.captcha = await load('6Le7iaUZAAAAADRiDsgRzIPjnbciVd8tF6tiysXR')

            Cookies.set('cookies-allowed', 'true', {
                sameSite: 'Strict'
            })
            this.cookiesAllowed = true
        }
    }
}
</script>

<style lang="scss" scoped>
@use '../styles/colors' as *;

form {
	position: relative;
	display: grid;
	grid-template-columns: 1fr 1fr;
	gap: 0.5em;

	.note {
        grid-column: span 2;
        font-size: 0.9rem;
        color: #808080;
    }

    small {
        color: grey;
    }

    input,
    textarea {
        padding: 0.3em;
        font-size: inherit;
        border: none;
        box-sizing: border-box;
        width: 100%;

        border-bottom: 1px solid white;
        transition: border-bottom .2s;
        &:focus {
            border-color: $blau;
            outline: none;
        }

        &:disabled {
            background-color: lightgrey;
            border-color: lightgrey;
        }
    }

	button {
		padding: 0.5em 1em;
		font-size: 1rem;
		color: #fff;
		background-color: $blau;
		border: none;
		cursor: pointer;

        $o-size: 2px;
        outline: $o-size solid $blau;
        outline-offset: -$o-size;
		transition: outline-offset 0.2s;

		&:hover,
        &:focus {
			outline-offset: $o-size;
		}

		&:disabled {
            cursor: not-allowed;
            background-color: lightgrey;
            outline-color: lightgrey;
        }
	}

	textarea {
		height: 4em;
		resize: vertical;
		font-family: inherit;
	}

    .overlay {
        $abstand: -1em;
		position: absolute;
		top: $abstand;
		right: $abstand;
		bottom: $abstand;
		left: $abstand;

        padding: 2em;
		// border-radius: .5em;
		// box-shadow: 0 0 .3em #000;
		background-color: rgba(0, 0, 0, 0.726);

		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;

		color: #fff;
		text-align: center;
    }

	.cookie-message {
        text-shadow: 0 0 1em black;

        animation: blend-in .3s .3s both;
        @keyframes blend-in {
            from {
                opacity: 0;
                transform: scale(.9);
            }
            to {
                opacity: 1;
                transform: scale(1);
            }
        }

        a {
            color: lightgrey;

            &:visited {
                color: grey;
            }
        }

        svg {
            font-size: .8em;
        }
	}

    .loading>svg {
        font-size: 2em;
        animation: spin 1s linear infinite;

        @keyframes spin {
            from {
                transform:rotate(0deg);
            }
            to {
                transform:rotate(360deg);
            }
        }
    }
}
</style>

<style lang="scss">
form .fx-relay-email-input-wrapper {
    z-index: 0;
}
</style>